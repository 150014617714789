<script setup lang="ts">
import { useModal } from 'vue-final-modal'
// import { useScrollToField } from '~/components/LeaseReview/composables/useScrollToField'
import type {
  ILeaseDocumentField,
  ILeaseSpecialCondition,
  ILeaseDocumentSpecialConditionsByKind,
} from '@register'

interface Props {
  specialConditions: ILeaseDocumentSpecialConditionsByKind
  disabled?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  disabled: false,
})

// const { scrollTo } = useScrollToField()
const { open, close } = useModal({
  component: getAsyncComponent('LeaseReviewSpecialConditionsModal'),
  attrs: {
    // onSubmit: async (field: ILeaseDocumentField) => {
    //   close()
    //   // await promiseTimeout(2000)
    //   // scrollTo(field.id)
    // },
    onSubmit: () => close(),
    onClose: () => close(),
  },
})

const onAdd = () => {
  open()
}

const parseField = (field: ILeaseSpecialCondition): ILeaseDocumentField => {
  return {
    ...field.leaseField!,
    isActive: true,
    value: {
      bounds: field.bounds ?? [],
      value: field.value,
      // originalValue: field.originalValue,
      isApproved: field.isApproved ?? false,
    } as ILeaseDocumentField['value'],
  }
}

const hasSpecialConditions = computed(
  () =>
    (props.specialConditions?.changed &&
      Object.entries(props.specialConditions.changed)?.length > 0) ||
    (props.specialConditions?.added &&
      Object.entries(props.specialConditions.added)?.length > 0) ||
    (props.specialConditions?.deleted &&
      Object.entries(props.specialConditions.deleted)?.length > 0) ||
    (props.specialConditions?.itemChanged &&
      Object.entries(props.specialConditions.itemChanged)?.length > 0),
)
</script>

<template>
  <FormKit
    type="form"
    :actions="false"
    form-class="grid w-full grid-cols-6 pr-2"
    :disabled="disabled"
    dirty-behavior="compare"
  >
    <!-- Section -->
    <div
      v-if="!hasSpecialConditions"
      class="border-gray-650 bg-gray-750 col-span-full my-8 flex items-center gap-4 rounded-lg border p-4 md:px-6"
    >
      <Icon name="warning" filled class="text-2xl" />
      There is no special conditions for this lease yet, add one by clicking the
      button bellow.
    </div>

    <!-- Add Button -->
    <div class="col-span-full flex justify-end py-2">
      <Button
        color="secondary"
        size="sm"
        class="self-end whitespace-nowrap"
        icon="plus"
        @click="onAdd"
      >
        Add a special condition
      </Button>
    </div>

    <!-- Clauses and Definitions -->
    <template v-for="(conditions, kind) in specialConditions" :key="kind">
      <div class="bg-gray-750 col-span-full p-4 py-2 md:pl-6">
        <div class="font-semibold">{{ title(kind) }}</div>
      </div>

      <div
        v-for="(clauseAndDefinition, id) in conditions"
        :key="id"
        class="col-span-full grid grid-cols-12 pl-4 md:pl-6"
      >
        <div class="col-span-3">
          <div
            class="sticky top-2 py-2 pb-10 pr-1 align-top text-sm font-medium"
          >
            {{ clauseAndDefinition.name }}
          </div>
        </div>
        <div class="col-span-9">
          <div v-if="clauseAndDefinition.definition" class="py-2">
            <div>
              <LazyLeaseReviewField
                :id="clauseAndDefinition.definition.id"
                :field="parseField(clauseAndDefinition.definition)"
                :special-condition="true"
                :can-delete="true"
                label="Definition"
              />
            </div>
          </div>
          <div v-if="clauseAndDefinition.clause" class="py-2">
            <LazyLeaseReviewField
              :id="clauseAndDefinition.clause.id"
              :field="parseField(clauseAndDefinition.clause)"
              :special-condition="true"
              :can-delete="true"
              label="Clause"
            />
          </div>
        </div>
      </div>
    </template>

    <!-- Add Button -->
    <div
      v-if="hasSpecialConditions"
      class="col-span-full flex justify-end py-2"
    >
      <Button
        color="secondary"
        size="sm"
        class="flex justify-center self-end whitespace-nowrap"
        @click="onAdd"
      >
        <Icon name="plus" />
        Add a special condition
      </Button>
    </div>
  </FormKit>
</template>
